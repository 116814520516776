import React from 'react'
import Helmet from 'react-helmet'
import { ToastContainer } from 'react-toastify'
import Headroom from 'react-headroom'

import favicon from '../images/favicon.ico'

import { getPageTitle, getPageSlug } from '@theme/utils/stringManipulations'

// import useGatsbyConfig from '@theme/hooks/useGatsbyConfig'
import siteConfig from '@theme/utils/siteConfig'

// Common Components
// import HeadroomHandler from "./HeadroomHandler";
import Header from '../components/Header'
import Footer from '../components/Footer'

const slugToDescriptionMap = {
  home: 'Promontory is a winegrowing endeavor founded by the Harlan family, hidden among the southwestern borderlands of Oakville in the Napa Valley.',
  visit:
    'The Promontory winery sits among the western hills of Oakville, overlooking the Napa Valley. Visitors are welcome, by appointment, for an introduction to our wine, philosophy, and discovery of the territory.',
  wine: `Wines from hillsides of Promontory reflect the many aspects of the territory: the native forests, the moisture of the ephemeral fog, and the minerality of the geologic underpinnings.`,
  'join-allocation': 'To request an allocation of Promontory wine, kindly submit the form here.',
  'bottle-verification': 'Please enter your Bottle ID to authenticate.',
}

const Layout = (props) => {
  const {
    // access,
    // allocationStatus,
    // customer,
    // isRelogged,
    // isLoadingData,
    // settings,
    location,
    children,
  } = props

  const pageSlug = getPageSlug(location.pathname)
  var pageTitle = getPageTitle(location.pathname)
  var siteTitle = siteConfig?.metadata?.title

  if (pageSlug == 'home') {
    pageTitle = siteConfig?.metadata?.title
    siteTitle = `Home | Story`
  } else if (pageSlug == 'visit') {
    pageTitle = `Visit Our Oakville Tasting Room & Winery`
    siteTitle = siteConfig?.metadata?.title + ' Napa Valley'
  } else if (pageSlug == 'wine') {
    pageTitle = `Napa Valley Wine`
    siteTitle = siteConfig?.metadata?.title + ' | Wine'
  } else if (pageSlug == 'members') {
    pageTitle = `Member Login`
  }

  const isHomePage = location.pathname === '/'

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>
          {pageTitle} | {siteTitle}
        </title>
        <meta name="description" content={slugToDescriptionMap[pageSlug] || ''} />
        <link rel="icon" href={favicon} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=8, minimum-scale=.25, user-scalable=yes"
        />
      </Helmet>

      {/* <ErrorBoundary> */}
      {/* <GeneralModalError /> */}
      {/* <GlobalStyles /> */}

      <div id={`main-page`} className={`${isHomePage ? 'home-page' : ''}`}>
        <ToastContainer
          position="top-left"
          autoClose={5000}
          hideProgressBar
          icon={false}
          style={{ width: '500px', maxWidth: '500px' }}
        />
        {/* <HeadroomHandler className={'hidden'} replaceLinks={[{from: '/', to: '#story'}]}> */}
        <Headroom>
          <Header {...props} />
        </Headroom>
        <main className="content-wrapper w-full mx-auto" id={pageSlug}>
          <div className="content-child w-full mx-auto overflow-hidden center">{children}</div>
        </main>
        <Footer {...props} />
      </div>
      {/* </ErrorBoundary> */}
    </>
  )
}

export default Layout
